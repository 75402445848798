import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div>
                <div className="main">
                    <p className="headerText">ABOUT</p>
                </div>

                <div className="aboutText">
                        <p className="helloText">Hello,</p> <br />
                        I Worked In Different Companies As A Software Developer. In My Working Life, I Mostly Used Technologies Such As Git, Linux, Python, Spark, Database And Machine Learning.<br /><br />
                        I Am Very Interested In Artificial Intelligence And I Am Advancing My Studies In This Field.<br /><br />
                        I Have Analytical Thinking Abilities And Am Successful In Problem Solving. I Take Great Care To Ensure That The Work I Do Is Perfect. Thanks To My Tendency To Teamwork And My Success In Communication, 
                        I Can Adapt To Projects Or Work Environments Very Quickly. In Addition To Having Work Discipline, Time Management Is Also Very Important To Me. I Make Plans And Prioritize To Complete Assigned Tasks On Time. 
                        I Love Working, Doing Research, Adding New Things To Myself In Every Sense, And That's Exactly Why I Am Prone To Teamwork And Individual Work.
                    </div>
            </div>

        )
    }
}

export default About