import React, { Component } from 'react';

class Portfolio extends Component {
    state = {
        portfolios: [
            {
                id: 1,
                title: "ETL Process Using Airflow and AWS S3",
                imageUrl: "https://cvws.icloud-content.com/B/Ablgz8uD-i96GSy3Z2d3NfF_n_OSAaoVAWdW_KWjrd7d9b3jm8DBG0df/airflow.png?o=AlztXhpspWCMj3q239qSiutKkb5CJ54Or_dTGA7sCWwj&v=1&x=3&a=CAogOJgvzjZ8geZs09P5Gw98Bt0PNbLxD2vI5IwNKCexBxMSbxD4_KC4mzIY-Nn8uZsyIgEAUgR_n_OSWgTBG0dfaie792x6HbAK8D__CPKIkHggiyPkKtP484ttHLzO_36ZuGP22UZCqltyJ8etjlxZD_atWp7Kv_4V7h2XPSZVLsjqF0svXucpdyP5D6GU5ExkHQ&e=1725356256&fl=&r=76711b1f-faa5-456a-b7c7-dc342915424f-1&k=aiZxlHyvAE4dw4L-VP_Mwg&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=103&s=e7qJyRnBfrAIFFT1Ai0aV9uefyg&cd=i",
                githubLink : "https://github.com/ugurcankok/Airflow_ETL_AWS",
                alt: "Airflow and AWS",
                text: "In this project, I will try how to build and automate an ETL process that can extract current weather data from open weather map API, transform the data and load t..."
            },
            {
                id: 2,
                title: "Real Time Data Analysis with Kafka, AWS",
                imageUrl: "https://cvws.icloud-content.com/B/AWYW8Z1_jEbpRSCaQvDGBeIeR4sKATgQrn22G_WUDvnDV_nexo3Apf63/Kafka.jpg?o=ArfpFTbJMGAnbpIx41RG_1Xn8Ca1WVS5GV1_hvqr_x41&v=1&x=3&a=CAogmQ-ys8XkKI-ilTtZwoAe-VmGa5PuKuQyEOrsZLUBXIcSbxCE1aW4mzIYhLKBupsyIgEAUgQeR4sKWgTApf63aidK0gB7bhnImxf4OgUQJK0YiewiVTjOd-bgOUZzVeH3DFhlcgQzRVRyJ7nTXJPPGfELi6qHDkY9vu1zD4V7JYe9FzWwbzsTOUr_JCsM2SI-Ag&e=1725356333&fl=&r=d07a0fc6-f47b-4e88-a493-bddf46562192-1&k=Lm0xGbZeeCuCSgKUZ51URg&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=103&s=KcIocGrem5q2X2K6zJrSkMfg7OM&cd=i",
                githubLink : "https://github.com/ugurcankok/Kafka_AWS",
                alt: "Kafka and AWS",
                text: "In this project, I executed a project on Real-Time Stock Market Data using Kafka. I used different technologies such as Python, Amazon Web Services (AWS), Apache K..."
            },
            {
                id: 3,
                title: "Data Streaming Airflow, Kafka, Spark",
                imageUrl: "https://cvws.icloud-content.com/B/AdDtTg5LBd5IqA_AvY7KUwfuwgzJAVumD6Y3fXJaT_lhbrwgad4il2kV/Data+engineering+architecture.png?o=Aqv1O8Uq1Pb63knfkxxoPinKJk_5Yndv2fr9ZhqpEanv&v=1&x=3&a=CAog7pPn3tqVMgvJzIv7ZF_EGSoxL9KlXwHS1ANRIunCYIUSbxDzgZ24mzIY8974uZsyIgEAUgTuwgzJWgQil2kVaifQy2kq2KghoDQbk7dxdr4Pr00_impkJiam2XFeQrZPQCqPFyI97ONyJ4MYpgPBH6POV22-BtQnt4XnmG9K_OXDg_8I5CILAwZhM8WY2h6Jqg&e=1725356191&fl=&r=6ad362d3-7ecd-491c-9c98-23587f324bab-1&k=EJpC_LL1K_0DJRLxONDiXQ&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=103&s=zmAEFkXoxTBIDc3hIYJUESOfsWs&cd=i",
                githubLink : "https://github.com/ugurcankok/Airflow_Kafka_Spark_Docker",
                alt: "Airflow, Kafka, Spark",
                text: "In this project, I cover each stage from data ingestion to processing and finally to storage, utilizing a robust tech stack that includes Apache Airflow, Python, A..."
            },
            {
                id: 4,
                title: "Movie Recommender System",
                imageUrl: "https://editor.analyticsvidhya.com/uploads/88506recommendation%20system.png",
                githubLink : "https://github.com/ugurcankok/Machine_Learning/tree/master/src/4.%20Project/Movie%20Recommendation",
                alt: "Machine Learning",
                text: "In this project, I worked on recommendation systems. I implemented the recommendation system on movie dataset. After completing the necessary part such as cleaning..."
            }
        ]
    }
    render() {
        return (
            <div>
                <div className="main">
                    <p className="headerText">PORTFOLIO</p>
                </div>

                <div className='mainBox'>
                    {this.state.portfolios.map(portfolio => (
                        <div className='box' key={portfolio.id}>
                            <img src={portfolio.imageUrl} className='image' alt={portfolio.alt}></img>
                            <p className='futureTitle'>{portfolio.title}</p>
                            <p className='futureText'>{portfolio.text}</p>
                            <div className='buttons'>
                                <a href={portfolio.githubLink} className="linkButtons">Source Code</a>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="special">
                    <a href="https://github.com/ugurcankok?tab=repositories" className="cvText">All Projects</a>
                </div>
            </div>
        )
    }
}

export default Portfolio