import React, { Component } from 'react';
import About from './About';
import Portfolio from './Portfolio';
import Footer from './Footer';

class Home extends Component {
    state = {
        social_links: [
            {
                id: 1,
                link: "https://www.linkedin.com/in/ugur-can-kok/",
                classes: "fa fa-linkedin"
            },
            {
                id: 2,
                link: "https://github.com/ugurcankok",
                classes: "fa fa-github"
            }
        ]
    }
    render() {
        return (
            <div>
                <div className="main">
                    <p className="mainText">UĞUR CAN KÖK</p>
                    <div className="special">
                        <p className="titleText">Software Developer</p>
                    </div>

                    <div className="specialLink">
                        {this.state.social_links.map(link => (
                            <div className="iconPosition" key={link.id}>
                                <a href={link.link}><i className={link.classes}></i></a>
                            </div>
                        ))}
                    </div>

                    <div className="centerArrow">
                        <div className="arrow bounce">
                            <i className="fa fa-angle-down"></i>
                        </div>
                    </div>
                </div>

                <About />
                <Portfolio />
                <Footer />
            </div>

        )
    }
}

export default Home