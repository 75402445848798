import React, { Component } from 'react';

class Footer extends Component {
    state = {
        social_links: [
            {
                id: 1,
                link: "https://www.linkedin.com/in/ugur-can-kok/",
                classes: "fa fa-linkedin"
            },
            {
                id: 2,
                link: "https://github.com/ugurcankok",
                classes: "fa fa-github"
            }
        ]
    }
    render() {
        return (
            <div>
                <div className='footerIcon'>
                    {this.state.social_links.map(link => (
                        <div className='icons' key={link.id}>
                            <a href={link.link}><i className={link.classes}></i></a>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}

export default Footer